<template>
  <n-h1 style="padding-top: 30px">{{ t("profile.disclaimer.title") }}</n-h1>
  <MarkdownViewer fullscreen :source="source">
    <template #footer>
      <n-space justify="space-between">
        <n-button type="error" size="large" @click="declineHandler">
          {{ t("commons.actions.decline") }}
        </n-button>
        <n-button type="success" size="large" @click="acceptHandler">
          {{ t("commons.actions.accept") }}
        </n-button>
      </n-space>
    </template></MarkdownViewer
  >
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { Auth } from "aws-amplify";
import { NH1, NButton, NSpace, useDialog } from "naive-ui";
import en_legal from "./en_dischargeResponsibility.md";
import es_legal from "./es_dischargeResponsibility.md";
import MarkdownViewer from "@/components/MarkdownViewer.vue";

export default {
  components: { NH1, MarkdownViewer, NButton, NSpace },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const router = useRouter();
    const dialog = useDialog();
    const is_acceptedDoR = computed(() => store.getters["user/is_acceptedDoR"]);

    const acceptHandler = async () => {
      await store.dispatch("app/lockUI");
      await store.dispatch("user/updateDoR", true);
      await store.dispatch("app/unlockUI");
      router.push({ name: "Home" });
    };

    const declineHandler = async () => {
      await store.dispatch("app/lockUI");
      await store.dispatch("user/updateDoR", false);
      await store.dispatch("app/unlockUI");
      dialog.warning({
        title: t("profile.disclaimer.dialogs.disclaimerRejected.title"),
        closable: false,
        maskClosable: false,
        content: t("profile.disclaimer.dialogs.disclaimerRejected.title"),
        positiveText: t("commons.confirmation.ok"),
        onPositiveClick: () => {
          Auth.signOut();
          store.dispatch("auth/clearContextUser").then(() => {
            router.push({ name: "Home" });
          });
        },
      });
    };

    return {
      t,
      acceptHandler,
      declineHandler,
      is_acceptedDoR,
      source: computed(() => {
        switch (locale.value) {
          case "en":
            return en_legal;
          case "es":
            return es_legal;
          default:
            return en_legal;
        }
      }),
    };
  },
};
</script>
<style>
.disclaimer {
  background-color: #f0f0f0;
  text-align: justify;
}
</style>
